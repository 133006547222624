<template>
  <div class="row">
    <div class="col">
      <Alerts ref="alert"></Alerts>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                <h5 class="mt-2 mb-0">Docentes</h5>
              </div>
              <div class="col">
                <ul class="nav justify-content-end">
                  <li class="nav-item">
                    <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                    <b>Filas por p&aacute;gina</b>
                  </li>
                  <li class="nav-item">
                    <select class="form-control" @change="sizeChange($event)"
                            v-model="filasPorPagina">
                      <option v-for="option in sizeoptions" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-dark" type="button"
                            @click="borrarFiltroBtnOnClick()">
                      <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                      Quitar Filtro
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-primary" data-html="true"
                            data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                            @click="nuevoBtnOnClick()">
                      <font-awesome-icon icon="fa-plus"/>
                      Nuevo
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-success" data-html="true"
                            data-toggle="tooltip"
                            title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                            type="button"
                            @click="excelBtnOnClick()">
                      <font-awesome-icon icon="fa-file-excel"/>
                      Exportar a Excel
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body p-0 pr-1 pe-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Docentes',
  components: {
    Alerts,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'C.I.', datafield: "documento_identificacion", width: '8%',
          renderer: function () {
            return '<div style="margin-top: 10px; margin-left: 5px;">C.I.</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por C.I."});
          }
        },
        {
          text: 'ApellidoPaterno', datafield: "apellido_paterno", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 10px; margin-left: 5px;">Apellido Paterno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido Paterno"});
          }
        },
        {
          text: 'ApellidoMaterno', datafield: "apellido_materno", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 10px; margin-left: 5px;">Apellido Materno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido Materno"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '14%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Celular', datafield: "celular", width: '8%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Celular"});
          }
        },
        // {
        //   text: 'Teléfono', datafield: "telefono", width: '8%',
        //   createfilterwidget: function (column, columnElement, widget) {
        //     widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Teléfono"});
        //   }
        // },
        {
          text: 'Usuario del sistema', datafield: "username", width: '10%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Usuario"});
          }
        },
        {
          text: 'Jefe de carrera', datafield: "jefe_carrera", width: '8%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: '', width: '8%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-warning btn-block ml-1" href="/#/docente/' + rowdata.id + '/editar" title="Editar datos del docente">Editar</a>';
          }, filterable: false, sortable: false
        },
        {
          text: '', width: '9%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-success btn-block ml-1" href="/#/docentemateria/' + rowdata.id + '/" title="Asignar Materias">Materias</a>';
          }, filterable: false, sortable: false
        },
        {
          text: '', width: '9%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-primary btn-block ml-1" href="/#/docente/' + rowdata.id + '/password" title="Cambiar contraseña de usuario">Usuario</a>';
          }, filterable: false, sortable: false
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docente/listar?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'documento_identificacion', type: "string"},
        {name: 'apellido_paterno', type: "string"},
        {name: 'apellido_materno', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'celular', type: "string"},
        {name: 'telefono', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'username', type: "string"},
        {name: 'jefe_carrera', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'apellido_paterno',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },
    getBadge(status) {
      return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
              : status === 'Pending' ? 'warning'
                  : status === 'Banned' ? 'danger' : 'primary'
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docente/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
  },
}
</script>